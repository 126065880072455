.skill-test-left {
	width: 20% !important;
	margin: 70px 20px auto 0;
}

.skill-test-right {
	width: 20% !important;
	margin: 70px 0 auto 20px;
}

#card-container {
	padding: 20px !important;
}

.top-container {
	display: flex !important;
}

.test-finish {
	background-color: #62449b !important;
	border: solid 2px #62449b !important;
}

.question-text {
	margin-top: 15px !important;
}

#question-q-text {
	font-weight: 400 !important;
}

#skill-card {
	width: 100% !important;
	margin: auto !important;
	margin-bottom: 40px !important;
	background-color: #111727;
	border: 1px solid rgba(0, 180, 180, 0.2);
	outline: none !important;
	padding: 10px !important;
	border-radius: 7px;
}

#result-container {
	display: none;
}

.display-results {
	display: block !important;
}

.hidden {
	display: none;
}

#skill-card-result {
	width: 50% !important;
	margin: auto !important;
	margin-bottom: 40px !important;
	border: none !important;
	outline: none !important;
	padding: 20px !important;
	border-radius: 7px !important;
}

.test-result {
	text-align: center !important;
	color: #000000;
}

.skill-q-text {
	font-weight: 600 !important;
}

.question-container {
	text-align: left;
	width: 50% !important;
}

.test-spacer {
	width: 100% !important;
}

.time-container {
	width: 50% !important;
	text-align: right !important;
}

.skill-border {
	color: rgba(0, 180, 180, 0.2);
}

.ul-btn {
	display: block;
	width: 100% !important;
}

.score-text {
	text-align: center;
	font-weight: bolder;
	font-size: 50px;
}

.top {
	text-align: center;
}

.answers-container {
	display: block;
	text-align: center;
	margin: auto;
	grid-gap: 20px;
	width: 100%;
}

.btn-container {
	display: inline-flex;
	width: 100%;
	margin: auto;
}

.quiz {
	outline: none !important;
	display: flex;
	width: 100% !important;
	margin-top: 10px;
	margin-bottom: 10px;
	background: none !important;
	border: solid 2px rgba(0, 180, 180, 0.2);
	color: #98a6ad;
	transition: 0.2s ease-in-out !important;
	border-radius: 5px;
	padding: 7px;
}

.selected {
	outline: none !important;
	display: flex;
	width: 100% !important;
	margin-top: 10px;
	margin-bottom: 10px;
	background-color: #62449b !important;
	border: solid 2px #62449b !important;
	color: rgb(255, 255, 255);
	border-radius: 5px;
	padding: 7px;
}

#code-editor {
	width: 100% !important;
	height: 40vh !important;
}

.result-btn {
	margin: 30px auto auto auto !important;
	width: fit-content !important;
}
