.certificate-container {
	max-width: 600px;
	margin: 0 auto;
	padding: 40px;
	background-color: #f9f9f9;
	border: 1px solid #ccc;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.certificate-header {
	text-align: center;
	margin-bottom: 40px;
}

.certificate-title {
	font-size: 28px;
	font-weight: bold;
	color: #333;
}

.certificate-body {
	margin-bottom: 40px;
}

.candidate-name {
	font-size: 24px;
	font-weight: bold;
	color: #333;
	margin-bottom: 20px;
}

.certificate-description {
	font-size: 18px;
	color: #555;
	line-height: 1.5;
	margin-bottom: 30px;
}

.certificate-result {
	font-size: 24px;
	font-weight: bold;
	color: #0077b5;
}

.loading {
	text-align: center;
	font-size: 20px;
	color: #555;
	padding: 20px;
}

/* Responsive styles */
@media (max-width: 768px) {
	.certificate-container {
		max-width: 90%;
		padding: 20px;
	}

	.certificate-title {
		font-size: 24px;
	}

	.candidate-name {
		font-size: 20px;
	}

	.certificate-description {
		font-size: 16px;
	}

	.certificate-result {
		font-size: 20px;
	}

	.loading {
		font-size: 16px;
	}
}
