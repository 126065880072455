.edit-profile-header {
	font-weight: 600 !important;
	margin-bottom: 35px !important;
	margin-top: 20px !important;
}

.css-13cymwt-control {
	border: none !important;
}

#form-skill {
	padding: 0 !important;
}

#update-btn {
	margin-top: 30px !important;
}

#form-card {
	display: -webkit-inline-box !important;
}

#card-form-1 {
	padding-right: 15px !important;
}

#card-form-2 {
	padding-left: 15px !important;
}

.cv-upload {
	margin-top: 40px !important;
}

.mandatory {
	color: red;
	font-size: 14px;
	top: 0.5px;
}

.candidate-reward-badge {
	max-height: 80px;
	max-width: 80px;
	object-fit: cover;
	margin: auto !important;
	text-align: center !important;
	justify-content: center;
}

.outer-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
}

.inner-container {
	display: flex;
	justify-content: left;
	align-items: center;
	flex-wrap: wrap;
	padding: auto !important;
}

.reward-container {
	width: 100px;
	display: block;
	justify-content: space-between;
	margin-right: 5px;
	margin-left: 5px;
}

#badge-title {
	margin-top: 5px !important;
	text-align: center !important;
}

#item-badge-row {
	justify-content: center;
	margin: auto;
}

#experience-company {
	font-size: 16px !important;
}

#experience-job {
	font-size: 14px !important;
}

#experience-hr {
	color: #e6edef;
	background-color: #e6edef;
	height: 1px;
	border: none;
}

.experience-container {
	display: flex;
	align-items: center;
}

.header-row-position {
	display: inline-flex;
	gap: 10px;
}

.experience-company-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.position-actions {
	display: flex;
	gap: 10px;
	align-items: center;
}

.position-actions {
	padding-bottom: 5px;
}

#work-experience-candidate {
	cursor: pointer;
	color: #00b4b4 !important;
	transition: 0.3s ease-in-out !important;
}

#work-experience-candidate:hover {
	color: #62449b !important;
}

.position-container {
	display: inline-flex;
}

.inner-position {
	margin: auto 5px;
}

.css-13cymwt-control {
	background-color: #111727 !important;
	color: rgba(255, 255, 255, 0.7) !important;
}

.css-1p3m7a8-multiValue {
	background-color: rgba(0, 180, 180, 0.2) !important;
	color: #fff !important;
}

.css-t3ipsp-control {
	background-color: rgba(0, 180, 180, 0.2) !important;
	color: #fff !important;
}

.css-wsp0cs-MultiValueGeneric {
	color: #fff !important;
}

.css-1dimb5e-singleValue {
	color: #fff !important;
}

#react-select-2-listbox,
#react-select-3-listbox {
	background-color: #111727 !important ;
	color: #fff !important;
}

#react-select-2-listbox:focus:hover {
	background-color: #62449b !important;
}

div.cv-upload input[type="file"]::file-selector-button {
	background-color: rgba(0, 180, 180, 0.2);
	color: #fff;
}

div.cv-upload input[type="file"]::file-selector-button:hover {
	background-color: rgba(0, 180, 180, 0.2);
	color: #fff;
}

div.pfp-upload input[type="file"]::file-selector-button {
	background-color: rgba(0, 180, 180, 0.2);
	color: #fff;
}

div.pfp-upload input[type="file"]::file-selector-button:hover {
	background-color: rgba(0, 180, 180, 0.2);
	color: #fff;
}

.css-d7l1ni-option {
	background-color: #152030 !important;
}

.css-1nmdiq5-menu {
	background-color: #111727 !important;
}
