.danger-btn {
	background-color: d22d3d !important;
}

.job-search-jobs {
	min-height: 30vh !important;
}

#summary-text {
	color: #999;
}

#job-span {
	font-weight: 600 !important;
	color: #7c7c7c;
}

.btn-applied {
	background-color: #62449b !important;
	border: solid 2px #62449b !important;
	color: #fff !important;
}

@media screen and (max-width: 1250px) {
	.job-search-jobs {
		min-height: 40vh !important;
	}
}

@media screen and (max-width: 850px) {
	#list-view-media {
		display: block !important;
	}

	#media-body-view {
		margin-top: 20px !important;
	}

	.job-apply-view {
		margin-top: 20px !important;
	}

	.job-search-jobs {
		min-height: 55vh !important;
	}
}

@media screen and (max-width: 575px) {
	.job-apply {
		margin-top: 10px !important;
	}

	.job-search-jobs {
		min-height: 65vh !important;
	}
}

@media screen and (max-width: 520px) {
	.job-search-jobs {
		min-height: 75vh !important;
	}
}

@media screen and (max-width: 400px) {
	.job-search-jobs {
		min-height: 85vh !important;
	}
}

@media screen and (max-width: 375px) {
	.job-search-jobs {
		min-height: 100vh !important;
	}
}
