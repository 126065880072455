#login-new-1 {
	margin: 0 20px 0 0 !important;
	background-color: #202938 !important;
}

#login-new-2 {
	margin: 0 0 0 20px !important;
	background-color: #202938 !important;
}

.login-card {
	background-color: #111727 !important;
}

#login-media {
	display: block;
}

.login-text {
	border-top: 1px solid rgba(0, 180, 180, 0.2);
}

.login-top {
	display: inline-flex;
}

.login-icon {
	margin-top: -20px;
	padding-bottom: 10px;
	left: 50%;
	right: 50%;
}

#login-name {
	margin: auto !important;
}

#google-login-btn {
	height: 100% !important;
	width: 60px !important;
	margin-right: 10px !important;
}

#git-login-btn {
	margin-right: 10px !important;
	width: 60px !important;
}

#linkedin-login-btn {
	width: 60px !important;
}

.login-card .login-main .theme-form h4,
.login-card .login-main .theme-form .h4 {
	color: #898989 !important;
}

@media screen and (max-width: 1040px) {
	#login-new-1 {
		margin: 0 20px 0 20px !important;
	}

	#login-new-2 {
		margin: 0 20px 0 20px !important;
	}
}

@media screen and (max-width: 650px) {
	.login-card {
		display: block !important;
	}

	.login-card {
		min-height: 170vh !important;
	}

	#login-new-1 {
		margin: auto auto 40px auto !important;
	}

	#login-new-2 {
		margin: auto !important;
	}
}

@media screen and (max-width: 550px) {
	#login-new-1 {
		width: 80% !important;
	}

	#login-new-2 {
		width: 80% !important;
	}
}

@media screen and (max-width: 350px) {
	#login-new-1 {
		width: 90% !important;
	}

	#login-new-2 {
		width: 90% !important;
	}
}
