#job-card {
	margin: auto !important;
	width: 100% !important;
}

#job-fragment {
	margin-bottom: 30px !important;
}

#jobCard {
	margin-bottom: 50px !important;
}

#danger-btn {
	background-color: d22d3d !important;
}

.bulletin-description {
	margin-top: 0 !important;
	border-top: 1px solid #ececec;
	padding-top: 0 !important;
}

@media screen and (max-width: 760px) {
	.job-detail {
		margin: 40px auto auto auto !important;
	}
}
