.edit-profile-header {
	font-weight: 600 !important;
	margin-bottom: 35px !important;
	margin-top: 20px !important;
}

.css-13cymwt-control {
	border: none !important;
}

#form-skill {
	padding: 0 !important;
}

#update-btn {
	margin-top: 30px !important;
}

#form-card {
	display: -webkit-inline-box;
}

#card-form-1 {
	padding-right: 15px !important;
	min-height: 700px !important;
}

#card-form-2 {
	padding-left: 15px !important;
}

.cv-upload {
	margin-top: 40px !important;
}

#form-notes {
	height: 200px;
	padding: 10px;
}

.contact-header {
	font-weight: 600 !important;
}

#card-form-1 {
	padding-right: 15px !important;
}

#card-form-2 {
	padding-left: 15px !important;
}

.login-text-contact {
	margin-top: -13px;
}

#con-main-card {
	min-height: 50vh !important;
}

#contact-header {
	font-weight: 600 !important;
	margin-bottom: 20px !important;
}

@media screen and (max-width: 1200px) {
	#card-form-1 {
		width: 50% !important;
	}

	#card-form-2 {
		width: 50% !important;
	}
}

@media screen and (max-width: 990px) {
	#form-card {
		display: grid !important;
	}
}

@media screen and (max-width: 770px) {
	.card-body {
		min-height: 40vh !important;
	}

	#card-con-2 {
		margin-top: -70px !important;
	}
}

@media screen and (max-width: 400px) {
	#card-con-2 {
		margin-top: -140px !important;
	}
}
