#article-card {
	position: relative !important;
}

#article-col {
	top: 20px !important;
	left: 0 !important;
	right: 0 !important;
}

#article-span {
	font-size: 36px;
	font-weight: 600;
	padding-right: 5px;
	color: var(--theme-default);
	line-height: 1;
}

.article-summary {
	margin-right: 10px;
}
