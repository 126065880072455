.main-box-image {
	width: 100% !important;
}

.side-blog-con {
	height: 100% !important;
}

.side-blog-images {
	height: 100% !important;
	object-fit: cover !important;
	width: 100% !important;
}

.blox-box-container {
	height: 534px !important;
}

.blog-main-image {
	min-height: 100% !important;
	width: 100% !important;
	object-fit: cover !important;
}

body.dark-only .page-wrapper.horizontal-wrapper .list-group-item,
body.dark-only .compact-wrapper .list-group-item {
	background-color: transparent !important;
}

.blog-box blog-shadow .blog-images {
	width: 100% !important;
	height: 200px !important;
	object-fit: cover !important;
	cursor: pointer !important;
}

.blog-images {
	height: 200px !important;
	object-fit: cover !important;
	cursor: pointer !important;
}

.gallery-blog-p {
	padding: 20px !important;
}

.blog-p-tags {
	text-align: left !important;
}

.blog-header-tag {
	text-align: left !important;
}

.blog-bottom-li {
	color: "#999" !important;
}

.icofont.icofont-thumbs-up.liked {
	color: #38b1bc;
}

.icofont.icofont-thumbs-up {
	transition: 0.5s ease-in-out;
}

.icofont.icofont-thumbs-up:hover {
	color: #38b1bc;
	cursor: pointer;
}

.article-tag {
	display: "inline-block";
	background-color: "#f0f0f0";
	border: "none";
	text-align: "center";
	text-decoration: "none";
	color: "black";
	padding: "5px";
	border-radius: "7px";
	font-size: "10px";
	margin-left: "5px";
	margin-right: "5px";
}

.news-filter-btn {
	width: 200px !important;
	margin-bottom: 20px !important;
	margin-top: 20px !important;
}

.infinite-scroll-component {
	overflow-x: hidden !important;
}

.article-tag {
	background-color: #f0f0f0;
	transition: 0.3s ease-in-out;
}

.article-tag:hover {
	background-color: #38b1bc;
	color: #fff !important;
}

body.dark-only
	.page-wrapper.horizontal-wrapper
	.page-body-wrapper
	.page-body
	.blog-box
	.blog-details
	.blog-social
	li:first-child,
body.dark-only
	.page-wrapper.horizontal-wrapper
	.page-body-wrapper
	.page-body
	.blog-box
	.blog-details-main
	.blog-social
	li:first-child,
body.dark-only
	.compact-wrapper
	.page-body-wrapper
	.page-body
	.blog-box
	.blog-details
	.blog-social
	li:first-child,
body.dark-only
	.compact-wrapper
	.page-body-wrapper
	.page-body
	.blog-box
	.blog-details-main
	.blog-social
	li:first-child {
	border-right: none !important;
}

body.dark-only
	.page-wrapper.horizontal-wrapper
	.page-body-wrapper
	.page-body
	.blog-box
	.blog-details
	.blog-social
	li,
body.dark-only
	.page-wrapper.horizontal-wrapper
	.page-body-wrapper
	.page-body
	.blog-box
	.blog-details-main
	.blog-social
	li,
body.dark-only
	.compact-wrapper
	.page-body-wrapper
	.page-body
	.blog-box
	.blog-details
	.blog-social
	li,
body.dark-only
	.compact-wrapper
	.page-body-wrapper
	.page-body
	.blog-box
	.blog-details-main
	.blog-social
	li {
	border-right: none !important;
}

body.dark-only
	.page-wrapper.horizontal-wrapper
	.page-body-wrapper
	.page-body
	.blog-box
	.blog-details
	.blog-social
	li,
body.dark-only
	.page-wrapper.horizontal-wrapper
	.page-body-wrapper
	.page-body
	.blog-box
	.blog-details-main
	.blog-social
	li,
body.dark-only
	.compact-wrapper
	.page-body-wrapper
	.page-body
	.blog-box
	.blog-details
	.blog-social
	li,
body.dark-only
	.compact-wrapper
	.page-body-wrapper
	.page-body
	.blog-box
	.blog-details-main
	.blog-social
	li {
	margin: 5px;
	padding: 0;
	border: none !important;
}

@media only screen and (max-width: 1600px) {
	.blog-box-wrapper {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	#persp-container {
		margin-top: 30px !important;
	}
}
