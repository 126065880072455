#cancel-mail {
	background-color: #62449b !important;
	border-color: #62449b !important;
}

.icon-hover:hover {
	color: #62449b;
	transition: 0.3s ease-in-out;
}

.react-datepicker__month {
	padding: 10px !important;
}

.react-datepicker__month-wrapper {
	margin: 10px 0 10px 0 !important;
	font-weight: normal !important;
	font-size: 15px !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
	border-radius: 0.3rem;
	background-color: #62449b !important;
	color: #fff;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
	font-weight: normal;
}

.react-datepicker {
	font-family: "Helvetica Neue", helvetica, arial, sans-serif;
	font-size: 0.8rem;
	background-color: #fff;
	color: #000;
	border-color: #e6edef !important;
	border-radius: 0.3rem;
	display: inline-block;
	position: relative;
}
