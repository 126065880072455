.img-thumbnail {
	padding: 0.25rem;
	background-color: #fff;
	max-width: 100%;
	height: auto;
}

.gallery-with-description a > div {
	padding: 10px;
	margin-bottom: 30px;
}

#test-thumbnail {
	max-height: 160px !important;
	min-width: 100% !important;
	object-fit: cover !important;
	border-radius: 5px !important;
	border: none !important;
	border-radius: 10px !important;
}

#test-cont {
	border: 1px solid rgba(0, 180, 180, 0.2);
	border-radius: 12px !important;
}

#test-body {
	padding: 10px !important;
}

.test-btn {
	background-color: #62449b !important;
	border-color: #62449b !important;
}
