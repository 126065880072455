#job-card {
	margin: auto !important;
	width: 100% !important;
}

#job-fragment {
	margin-bottom: 30px !important;
}

#jobCard {
	margin-bottom: 50px !important;
}

#danger-btn {
	background-color: d22d3d !important;
}

@media screen and (max-width: 1200px) {
	#job-detail-media {
		display: block !important;
	}
}

@media screen and (max-width: 760px) {
	.job-detail {
		margin: 10px auto auto auto !important;
	}
}
