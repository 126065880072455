#test-confirm-card {
	padding: 20px !important;
}

#test-confirm-header {
	text-align: center !important;
	font-weight: 600 !important;
}

#test-confirm-body {
	text-align: center !important;
	font-weight: 500 !important;
}

.test-buttons {
	margin: 20px auto auto auto !important;
}

.cancel-test {
	margin-right: 10px !important;
	background-color: #62449b !important;
	border: solid 2px #62449b !important;
}

.start-test {
	margin-left: 10px !important;
}
